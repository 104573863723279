// dev cdn
export const CDN_BASE_URL = `${process.env.REACT_APP_CDN_BASE_URL}/`;

// LD
export const REACT_APP_LAUNCH_DARKLY_CLIENT_KEY = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY
export const LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY
export const LAUNCH_DARKLY_ENABLE_BANNER_KEY = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_BANNER_KEY
export const LAUNCH_DARKLY_ENABLE_SHOW_BUNDLE = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_SHOW_BUNDLE
// export const STATIC_SHOP = "jzdev.myshopify.com"
// export const LAUNCH_DARKLY_ENABLE_MICROSERVICE_KEY = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_MICROSERVICE_KEY

export const LAUNCH_DARKLY_ENABLE_WIX_PRODUCT_OPTIONS_TO_DISPLAY = process.env.REACT_APP_LAUNCH_DARKLY_ENABLE_WIX_PRODUCT_OPTIONS_TO_DISPLAY

// Datadog
export const APPLICATION_ID = '49c10eac-1287-4105-9ad2-cdf07aea3ad7'
export const CLIENT_TOKEN = 'pubc2cba0a189513198f27334998e86cc66'
export const ENV = 'dev'
export const SERVICE = 'unified-editor'

// preview bundle URLs
export const CE_BUNDLE_URL = process.env.REACT_APP_CE_BUNDLE_URL

// Login api local url
export const API_URL = `${process.env.REACT_APP_API_URL}/api/v1/`

// crisp config
export const CRISP_WEBSITE_ID = '56918d27-713e-4f7f-9786-961982ae4884'

// platform
export const PLATFORM = process.env.REACT_APP_PLATFORM;

export const IS_LOCAL = process.env.REACT_APP_IS_LOCAL;

export const FROALA_EDITOR_KEY = process.env.REACT_APP_FROALA_EDITOR_LICENSE_KEY;

// onboarding modal
export const ONBOARDING_VIDEO_URL = 'https://www.youtube.com/watch?v=l2BhGwJr_8Q'

// Wix app id
export const APP_ID = process.env.REACT_APP_APP_ID;

// Shopify app Id 
export const SHOPIFY_APP_ID = process.env.REACT_APP_SHOPIFY_API_KEY;

// flow builder url
export const CONDITIONS_EDITOR_URL = process.env.REACT_APP_CONDITIONS_EDITOR_URL;