import React, { useContext, useState, useEffect } from 'react';
import { t } from 'i18next';

import { PageData } from '../../../../../../ContextAPI/Context';
import { canUpdateField, checkTargetConditions, getSelectedWidget, getTargetedValue } from '../../../../../../Utils/Common';
import RenderComponent from '../RenderComponent';
import { Header, Subheader, Tooltip } from '../../../../../../Shared/Components';
import { MultiProduct, ThreeSectionLayout, WidgetType } from '../../../../../../Constant';
import TimerSection from '../../TimerSection';

const RenderRightComponent = (props) => {
  const { latestConfigSetting, unifiedEditor, previewType, systemSetting, isDynamicSync, isMobileView,setCurrentWidget } = useContext(PageData);
  const [title, setTitle] = useState('');
  const [widgetSettings, setWidgetSettings] = useState({});
  const { id, widgetId, parentWidgetId, target, blockType, componentType, parentWidgetType } = props.type;

  useEffect(() => {
    const targetedWidget = getTargetedValue(latestConfigSetting, target, widgetId, parentWidgetId, blockType, id);
    const selectedWidget = getSelectedWidget(targetedWidget, unifiedEditor.widgets, previewType, parentWidgetType);
    setCurrentWidget(selectedWidget);
    if (([WidgetType.Text, WidgetType.Banner, WidgetType.MultiProductUpsell, WidgetType.Bundle, WidgetType.Recommendations, WidgetType.Timer, WidgetType.Divider].includes(selectedWidget?.type) ||
      ThreeSectionLayout.includes(selectedWidget?.type)) && !selectedWidget?.blockName) {

      const widgetData = { ...selectedWidget, blockSettings: selectedWidget?.sectionSettings }
      const sectionTitle = MultiProduct.includes(selectedWidget.type) ? selectedWidget?.title : selectedWidget?.sectionName;
      setTitle(sectionTitle);
      setWidgetSettings(widgetData);
    } else {
      setWidgetSettings(selectedWidget);
      setTitle(selectedWidget?.blockName || selectedWidget?.sectionName);
    }
    return () => {
      setTitle('');
      setWidgetSettings({});
    }
  }, [latestConfigSetting, unifiedEditor.widgets, target, id, widgetId, parentWidgetId, blockType, parentWidgetType, isDynamicSync]);
  
  // When moving a block between columns (left, right, or bottom) to another column (left, right, or bottom), verify the condition of the destinationWidgetId with the current widgetId.
  const componentExist = latestConfigSetting?.destinationWidgetId ? latestConfigSetting?.destinationWidgetId === widgetId : true;
  
  try {
    return (
      <div className='section-details-wrap'>
        <Header label={title} showHelpLink/>
        {
          // When the RenderRightComponent is invoked, it will display the previously stored widgetSetting data. Therefore, a condition is implemented to ensure that the widgetSetting and componentType are equal before rendering the component.
        }
        {widgetSettings?.count > 0 && widgetSettings?.type === componentType && componentExist ?
          <>
            {widgetSettings?.type === WidgetType.Divider ?
              (
                <div className='divider-text'>
                  {t('no_customizable_settings_available')}
                </div>
              ) :
              widgetSettings.blockSettings.map((setting, index) => {
                if (setting.conditional && !checkTargetConditions({widgets: latestConfigSetting, target: `${target}.${setting.target}`, conditions: setting.condition, widgetId: widgetId, parentWidgetId: parentWidgetId, _type: blockType, id: id, isMobileView})) {
                  return null;
                } else {
                  return (
                    <React.Fragment key={index}>
                      {setting?.settingGroups ?
                        (
                          <>
                            {widgetSettings?.type === WidgetType.Timer ? (
                              <>
                                <TimerSection data={{ setting, id, widgetId, parentWidgetId, target, componentType: componentType, syncSettings: widgetSettings?.syncSettings }} />
                                {widgetSettings?.blockSettings?.length !== index && <div className='content-divider' />}
                              </>
                            ) : (
                              <>
                                <Subheader label={setting.label} helpText={setting.helpText} systemSetting={systemSetting}/>
                                {setting.settingGroups.map((subSetting, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      {(subSetting.updateConditions && !canUpdateField(latestConfigSetting, `${target}`, subSetting.updateConditions, widgetId, parentWidgetId, blockType, id)) ?
                                        <Tooltip tooltipText={subSetting.tooltipText}>
                                          <RenderComponent data={{ setting: subSetting, id, widgetId, parentWidgetId, target, componentType: blockType, syncSettings: widgetSettings?.syncSettings }} />
                                        </Tooltip> :
                                        <RenderComponent data={{ setting: subSetting, id, widgetId, parentWidgetId, target, componentType: blockType, syncSettings: widgetSettings?.syncSettings }} />
                                      }
                                    </React.Fragment>
                                  )
                                })}
                                {widgetSettings?.blockSettings?.length - 1 !== index && <div className='content-divider' />}
                              </>
                            )}
                          </>
                        ) :
                        <RenderComponent data={{ setting, id, widgetId, parentWidgetId, target, componentType: blockType, syncSettings: widgetSettings?.syncSettings }} />
                      }
                    </React.Fragment>
                  )
                }
              })
            }
          </> :
          null
        }
      </div>
    )
  } catch (err) {
    return (
      <>
        <div className='m-5 text-center text-secondary'>Something went wrong..</div>
      </>
    )
  }
}

export default RenderRightComponent;