import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Select as PolarisSelect } from '@shopify/polaris';
import { Select as ChakraSelect } from '@chakra-ui/react';

import styles from './DropDown.module.css';
import Label from '../Label';


import { t } from 'i18next';
import { PageData } from '../../../ContextAPI/Context';
import { LAUNCH_DARKLY_ENABLE_WIX_PRODUCT_OPTIONS_TO_DISPLAY, PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Dropdown = (props) => {
    const { showTooltip, label, tooltipText, helpText, options, onChange, value } = props;
    const { setHelpModalData, helpLinks } = useContext(PageData);
    const [valueType, setIsValueType] = useState("")
    const flags = useFlags();
    const isShowProductTodisplayOption = flags[LAUNCH_DARKLY_ENABLE_WIX_PRODUCT_OPTIONS_TO_DISPLAY];

    useEffect(() => {
        const elements = document.getElementsByName('HELP_TEXT');

        for (const element of elements) {
            element.addEventListener('click', handleHelp);
        }

        return () => {
            for (const element of elements) {
                element.removeEventListener('click', handleHelp);
            }
        };
    }, []);

    const handleHelp = (event) => {
        const helpKey = event.target.getAttribute('data-help-key');
        setHelpModalData(helpLinks[helpKey]);
    };

    const translatedOptions = useMemo(() => {
        if (label === 'product_to_display' && !isShowProductTodisplayOption) {
            const newWixOptions = [t('frequently_viewed_together'), t('frequently_bought_together'), t('from_the_same_category'), t('best_sellers')]
            return options?.map(item => {
                return { ...item, label: t(item.label) }
            }).filter(data => !newWixOptions.includes(data.label));
        }
        return options?.map(item => {
            return { ...item, label: t(item.label) }
        });
    }, [options, isShowProductTodisplayOption, label])

    const optionHelpText = useMemo(() => {
        const option = options.find(item => item.value === value);
        return option?.helpText ? option.helpText : '';
    }, [options, value]);

    useEffect(()=> {
        setIsValueType(Array.isArray(value) ? "array" : typeof value)
    },[value])

    const handleChange = (e) => {
        if (valueType === "array") {
            onChange([e.target.value])
        } else {
            onChange(e.target.value)
        }
    }

    return (
        <div className={styles.input_wrap}>

            {label && <Label label={t(label)} tooltipText={tooltipText} showTooltip={showTooltip} />}

            {PLATFORM === PlatformType.Wix ?
                <ChakraSelect onChange={(e) => handleChange(e)} value={value} className={styles.select_wrap}>
                    {translatedOptions?.map((item) => {
                        return <option key={item.value} value={item.value}>{item.label}</option>
                    })}
                </ChakraSelect>
                :
                <PolarisSelect
                    options={translatedOptions}
                    onChange={onChange}
                    value={value}
                />
            }

            {optionHelpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: t(optionHelpText) }} />}
            {helpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: t(helpText) }} />}
        </div>
    )
}

export default Dropdown;