import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchPreviews, fetchStoreInfo, updatePreview, verifyAuth } from '../Service';
import { cloneDeep } from 'lodash';
import { PlatformType, QueryParams, PreviewType, WidgetType, WidgetTypeWiseCategory, WidgetCategoryType, TargetKeys, Layouts, UpsellBlocks } from '../Constant';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { LAUNCH_DARKLY_ENABLE_BANNER_KEY, LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY, PLATFORM, CE_BUNDLE_URL, IS_LOCAL, APP_ID, CRISP_WEBSITE_ID } from '../config';
import checkout_shopify_editor from '../Mock/checkout_shopify_editor.json';
import UnauthorizedPage from '../Components/UnauthorizedPage';
import checkout_wix_editor from '../Mock/checkout_wix_editor.json';
import post_purchase_shopify_editor from '../Mock/post_purchase_shopify_editor.json';
import order_status_page_shopify_editor from '../Mock/order_status_page_shopify_editor.json';
import thank_you_page_shopify_editor from '../Mock/thank_you_page_shopify_editor.json';
import { Crisp } from 'crisp-sdk-web';
import { changeLayout, isOpenedFromFlowBuilder, editEditorSchema, getTargetedValue } from '../Utils/Common';
import helpUrls from '../Mock/help_urls.json';

export const PageData = createContext();

const editors = {
  [PlatformType.Shopify]: {
    [PreviewType.CHECKOUT]: checkout_shopify_editor,
    [PreviewType.POST_PURCHASE]: post_purchase_shopify_editor,
    [PreviewType.ORDER_STATUS_PAGE]: order_status_page_shopify_editor,
    [PreviewType.THANK_YOU_PAGE]: thank_you_page_shopify_editor,
  },
  [PlatformType.Wix]: {
    [PreviewType.CHECKOUT]: checkout_wix_editor
  },
}

const helpJson = {
  [PlatformType.Shopify]: {
    [PreviewType.CHECKOUT]: helpUrls.shopify.checkout,
    [PreviewType.POST_PURCHASE]: helpUrls.shopify.postPurchase,
    [PreviewType.ORDER_STATUS_PAGE]: helpUrls.shopify.checkout,
    [PreviewType.THANK_YOU_PAGE]: helpUrls.shopify.checkout,
  },
  [PlatformType.Wix]: {
    [PreviewType.CHECKOUT]: helpUrls.wix.checkout,
  }
}

const JsonData = ({ children, handleLoading }) => {
  // Used only in this context
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [platformStoreId, setPlatformStoreId] = useState(null);

  const ldClient = useLDClient();
  const flags = useFlags();

  // Context states and reducers
  const [configSetting, setConfigSetting] = useState([]);
  const [redoConfigSetting, setRedoConfigSetting] = useState([]);

  const [unifiedEditor, setUnifiedEditor] = useState();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [selectedSection, setSelectedSection] = useState('');
  const [previewType, setPreviewType] = useState();
  const [systemSetting, setSystemSetting] = useState();
  const [rightBarLinks, setRightBarLinks] = useState([]);
  const [rightBarData, setRightBarData] = useState([]);
  const [currentWidget, setCurrentWidget] = useState({});
  const [isUpsellWidget, setIsUpsellWidget] = useState(false);
  const [upsellWidgetBlock, setUpsellWidgetBlock] = useState({});
  const [selectedWidgetId, setSelectedWidgetId] = useState('');
  const [selectedLink, setSelectedLink] = useState({});
  const [helpModalData, setHelpModalData] = useState({});
  const [previewSelectedSections, setPreviewSelectedSection] = useState({ parentId: '', id: '', isBlock: false });
  const [bannerStatus, setBannerStatus] = useState({});
  const [isHideLeftBarBanner, setIsHideLeftBarBanner] = useState(true);
  const [addWidgetByTarget, setAddWidgetByTarget] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [settingType, setSettingType] = useState('');
  const [requiredData, setRequiredData] = useState({ stopChanges: false, showToast: false, showErrorMessage: false, toastMessage: '', isDisable: false });
  const [isRightBarEmpty, setIsRightBarEmpty] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSelectProduct, setIsSelectProduct] = useState(false)

  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [isAppIntegrated, setIsAppIntegrated] = useState(false);
  const [appInstallationUrl, setAppInstallationUrl] = useState("");
  const [showCheckStatusButton, setIsCheckStatus] = useState(false);
  const [isCheckStatusDescription, setIsCheckStatusDescription] = useState(false);
  const [isInitWidgetEmpty, setIsInitWidgetEmpty] = useState(false);
  const [selectedWidgetType, setSelectedWidgetType] = useState('');
  const [helpLinks, setHelpLinks] = useState({});
  const [onBoardingModalData, setOnBoardingModalData] = useState({ showModal: false, type: 'B', isError: false, setUpManually: false });
  const [isAddSection, setIsAddSection] = useState({ isAdd: false, handleSubmit: false });
  const [isPublishedData, setIsPublishedData] = useState(false);
  const [isOnboardSubmit, setIsOnboardSubmit] = useState(false);
  const [isShowWidgetLimitModal, setIsShowWidgetLimitModal] = useState(false);
  const [toastMessage, setToastMessage] = useState({});
  const [isPositionChangeOfWidget, setIsPositionChangeOfWidget] = useState(false);
  const [isDynamicSync, setIsDynamicSync] = useState(false);
  const [queryParams, setQueryParams] = useState({});

  const isDiscountEnabled = flags[LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY] === false ? false : true;
  const isBannerEnable = flags[LAUNCH_DARKLY_ENABLE_BANNER_KEY];
  const isPlatformWix = PLATFORM === PlatformType.Wix;

  const sendPostMessage = useCallback((event, isSaveDisabled) => {
    // send post message to parent window to show help button and save button in title bar
    PLATFORM === PlatformType.Shopify &&
    (window.opener || window.parent).postMessage(
        {
            eventType: event,
            data: { page: "flow-editor", showHelpButton: true, showSaveButton: true, disabledSaveButton: isSaveDisabled },
        },
        "*",
    );
  }, []);

  useEffect(() => {
    // This condition is for bundle widgets to resolved render issue when view type changed
    setIsPositionChangeOfWidget(false);
  }, [isMobileView]);

  const updateData = useCallback((res) => {
    res = { ...res, selected: previewSelectedSections };
    setConfigSetting((prev => [...prev, res]))
  }, [previewSelectedSections]);

  const updateCategory = (widget) => {
    if (widget.type === WidgetType.Banner && widget.button.display) {
      widget.category = WidgetCategoryType.clickable;
    } else {
      widget.category = WidgetTypeWiseCategory[widget.type];
    }
  }

  const removeUnwantedKeysForBundle = (widget) => {
    if(widget.type === WidgetType.Bundle) {
      widget.widgets.forEach(subWidget => {
        if(subWidget.type === WidgetType.Upsell) {
          delete subWidget.upsellInfo.noOfProducts;
          delete subWidget.upsellInfo.layout;
        }
      });
    }
  }

  // check missing properties and add them to response
  const addMissingKeys = (resWidget, defaultSection, defaultSections) => {
    for (let key in defaultSection) {
      // eslint-disable-next-line no-prototype-builtins
      if (!resWidget.hasOwnProperty(key)) {
         // added belows condition because from editor side the label is nagetive type value but the key we are passing on save has type of positive side and because of that the functionlity was not working properly for hide the compare to price 
        if (key === 'dontDisplayComparePrice') {
          resWidget[key] = resWidget['enabled'];
          resWidget['enabled'] = !resWidget[key];
        } else if (key === 'dontDisplayCompareTotalPrice') {
          resWidget[key] = resWidget['isDisplayCompareToPrice'] ?? defaultSection['isDisplayCompareToPrice'];
          resWidget['isDisplayCompareToPrice'] = !resWidget[key];
        } else {
          resWidget[key] = defaultSection[key];
        }
      } else if (key === TargetKeys.Blocks ) {
        resWidget[key].map((block) => {
          let defaultSectionBlock;
          if (block?.type === UpsellBlocks.TotalPrice) {
            defaultSectionBlock = defaultSections.find((section) => section.type === block.type);
          } else {
            defaultSectionBlock = defaultSection[key].find((section) => section.type === block.type);
          }
          addMissingKeys(block, defaultSectionBlock, defaultSections);
        })
      } else if (key === TargetKeys.Widgets) {
        resWidget[key].map((widget) => {
          const defaultSectionWidget = defaultSections.find((section) => section.type === widget.type);
          addMissingKeys(widget, defaultSectionWidget, defaultSections);
        })
      }  else if (typeof resWidget[key] === TargetKeys.Object) {
        // If the value of the key is an object, recursively check and add missing keys
        addMissingKeys(resWidget[key], defaultSection[key], defaultSections);
      }
    }
    return resWidget;
  }

  // Check if parent type is missing in response then add parentType to nested widgets
  const addParentTypeProperty = (resWidget) => {
    if (resWidget?.widgets?.length) {
      resWidget.widgets.forEach((subWidget) => {
        subWidget.parentType = resWidget.type;
        if(subWidget?.widgets?.length){
          addParentTypeProperty(subWidget);
        }
      });
    }
    return resWidget;
  }

  const updateResponse = useCallback((response, schema) => {
    let configWidgets = getTargetedValue(response, 'widgets');
    configWidgets.map((widget) => {

      //Added missing properties in widgets from default widgets properties
      const defaultWidget = schema.defaultSections.find((section) => section.type === widget?.type);
      addMissingKeys(widget, defaultWidget, schema.defaultSections);

      // Added parentType for nested widgets
      addParentTypeProperty(widget);

      removeUnwantedKeysForBundle(widget);

      // Add category to widget when category is not defined
      if (widget && !widget.category) {
        updateCategory(widget);

        // Setting category for nested widgets
        if (widget.widgets) {
          widget.widgets.forEach((subWidget) => {
            updateCategory(subWidget);
          });
        }
      }

      // convert layout ONE_COLUMN to TWO_COLUMN or THREE_COLUMN for 'POST_PURCHASE' preview type
      if (widget?.blocks?.length > 0) {
        const layout = widget?.upsellInfo?.layout;
        // When layout is changed ONE_COLUMN to TWO_COLUMN or THREE_COLUMN column then added internal widgets like : "Left", "Right", "Bottom" and seprate blocks for 2 and 3 column layouts using location 1, 2, 3
        if ([Layouts.TWO_COLUMN, Layouts.THREE_COLUMN].includes(layout)) {
          widget = changeLayout(widget, layout, Layouts.ONE_COLUMN);
        }
      }
      return widget;
    });

    updateData(response);
  }, []);

  const latestConfigSetting = useMemo(() => {
    return structuredClone(configSetting[configSetting.length - 1]);
  }, [configSetting]);

  const updateSubmitResponse = useCallback((response, previewType, defaultLayout) => {
    let configWidgets = getTargetedValue(response, 'widgets');
    configWidgets.map((widget) => {
      if (widget.type === WidgetType.Upsell && previewType === PreviewType.POST_PURCHASE) {
        const layout = widget?.upsellInfo?.layout;
        // When layout is TWO_COLUMN or THREE_COLUMN then change to ONE_COLUMN layout and save the respoanse
        if ([Layouts.TWO_COLUMN, Layouts.THREE_COLUMN].includes(layout)) {
          widget = changeLayout(widget, defaultLayout, layout);
        }
      }
      return widget;
    });
    const { ...res } = response;
    return res;
  }, []);

  // Check remove button can be hide for last widget, parent widget or block
  const checkRemovable = useCallback((minCount, count) => {
    // Widget must be exist at least once
    if (minCount === 1 && count === 1) {
      return false;
    }

    // const latestSetting = configSetting[configSetting.length - 1];
    // Block not selected means only widget or parent widget selected
    // if (!id) {
    //   // Outer Widget selected
    //   if ((widgetId && !parentWidgetId) || (parentWidgetId && !widgetId)) {
    //     return latestSetting?.widgets?.length !== 1;
    //   }
    //   // Nested Widget selected
    //   else if (parentWidgetId && widgetId) {
    //     // Get Parent widget target string, so removing widget + widget id from current target string
    //     const parentTarget = getTargetString(target, 2, 2);
    //     const targetedWidget = getTargetedValue(latestSetting, parentTarget, widgetId, parentWidgetId);
    //     return targetedWidget?.widgets?.length !== 1;
    //   }
    // }
    return true;
  }, [configSetting]);


  //Set default widgets data (Only for WIX platform)
  useEffect(() => {
    if(isPlatformWix && latestConfigSetting && latestConfigSetting.widgets.length > 0 && previewSelectedSections.id === ""){       
      const selectedObj = {parentId: null, id: latestConfigSetting.widgets[0].id}       
        setPreviewSelectedSection(selectedObj)      
        setSelectedSection({ id: selectedObj.id, isOpen: true });    
    }
  }, [latestConfigSetting,PLATFORM]);
  
  useEffect(() => {
    ldClient && platformStoreId && ldClient.identify({ key: platformStoreId });
  }, [ldClient, platformStoreId]);

  const undoFun = useCallback(() => {
    if (configSetting.length > 1) {
      setShowProgressBar(true);

      let configSettingClone = cloneDeep(configSetting);
      const undoState = configSettingClone.pop();

      setRedoConfigSetting((config) => [...config, undoState]);
      setConfigSetting(configSettingClone);

      if (configSettingClone.length > 0) {
        const lastConfigSetting = configSettingClone[configSettingClone.length - 1];
        if (lastConfigSetting.widgets.length && lastConfigSetting.selected && lastConfigSetting.selected.id) {
          setSelectedSection({ id: lastConfigSetting.selected.id, isOpen: true });
        }
      }

      if (undoState.sectionAdded) {
        setIsRightBarEmpty(true);
      }
      else {
        setIsRightBarEmpty(false);
      }

      setTimeout(() => {
        setShowProgressBar(false);
      }, 500);
    }
  }, [configSetting]);

  const redoFun = useCallback(() => {
    if (redoConfigSetting.length > 0) {
      setShowProgressBar(true);
      let redoConfigSettingClone = cloneDeep(redoConfigSetting);
      const redoState = redoConfigSettingClone.pop();

      setRedoConfigSetting(redoConfigSettingClone);
      setConfigSetting((config) => [...config, redoState]);

      if (redoConfigSettingClone.length > 0) {
        const lastConfigSetting = redoConfigSettingClone[redoConfigSettingClone.length - 1];
        if (lastConfigSetting.widgets.length && lastConfigSetting.selected && lastConfigSetting.selected.id) {
          setSelectedSection({ id: lastConfigSetting.selected.id, isOpen: true });
        }
      }

      setTimeout(() => {
        setShowProgressBar(false);
      }, 500);
    }
  }, [redoConfigSetting]);

  const submitData = useCallback(async (configParams) => {
    setShowProgressBar(true);
    const data = configParams ? configParams : configSetting[configSetting.length - 1];
    const dataClone = cloneDeep(data);
    const responseData = updateSubmitResponse(dataClone, previewType, Layouts.ONE_COLUMN);
    
    // segments track event
    // let editedData = dataClone;
    // if(editedData && PLATFORM === PlatformType.Wix) {
    //   editedData.email = systemSetting?.email
    //   editedData.storeUrl = systemSetting.storeUrl
    //   editedData.userID = systemSetting.instance_id
    //   editedData = addSegmentEventProperties(configSetting[0], editedData)
    // }

    try {
      await updatePreview(responseData);
      sendPostMessage("hide-save-bar"); // When isNewVariant is true and a widget is added by default, then hide the unsaved bar after the data is saved.
      setConfigSetting([data]);
      setRedoConfigSetting([]);

      // segments track event
      // if (editedData && PLATFORM === PlatformType.Wix) {
      //   let eventData = {
      //     id: systemSetting.instance_id,
      //     event: 'Checkout template edited',
      //     data: editedData
      //   }
        
      //   trackSegmentsService(
      //     eventData,
      //     res => {
      //       console.log('Event tracked', res)
      //     },
      //     err => {
      //       console.log(err);
      //     });
      // }

      return true;
    } catch (error) {
      return false;
    } finally {
      setShowProgressBar(false);
    }
  }, [configSetting, previewType]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (navigator.userAgentData.platform === 'macOS') {
        if (e.metaKey && e.key === 's') {
          e.preventDefault();
          submitData();
        } else if (e.metaKey && e.key === 'y') {
          e.preventDefault();
          redoFun();
        } else if (e.metaKey && e.key === 'z') {
          e.preventDefault();
          undoFun();
        }
      } else if (navigator.userAgentData.platform === 'Windows') {
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault();
          submitData();
        } else if (e.ctrlKey && e.key === 'y') {
          e.preventDefault();
          redoFun();
        } else if (e.ctrlKey && e.key === 'z') {
          e.preventDefault();
          undoFun();
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [undoFun, redoFun, submitData]);

  useEffect(() => {
    getInitialData();
    setTimeout(() => {
      setShowProgressBar(false);
    }, 500);
     
  }, []);

  const chatbot = (data) => {
    const store_client_id = data?.storeClientId ? data.storeClientId : '';
    const store_email = data?.email ? data.email : '';
    const shop_owner = data?.shopOwner ? data.shopOwner : '';
    const shop_name = data?.shopName ? data.shopName : '';
    const store_url = data?.storeUrl ? data.storeUrl : '';

    Crisp.configure(CRISP_WEBSITE_ID);
    Crisp.setTokenId(store_client_id);

    Crisp.user.setEmail(store_email);
    Crisp.user.setNickname(shop_owner);
    Crisp.user.setCompany(shop_name, {
      url: store_url
    });

    Crisp.session.setData({
      storeName: shop_name,
      storeUrl: store_url
    });

    Crisp.chat.show();
  }

  const setDataForEditor = (queryParams, storeInfoRes) => {
    //chatbot setup using crisp library
    chatbot(storeInfoRes);

    // Set editor json by type
    const editorSchema = editors[PLATFORM][queryParams.preview];
    const schema = editEditorSchema(editorSchema, queryParams.preview, storeInfoRes);
    const helpLinkJson = helpJson[PLATFORM][queryParams.preview];
    setHelpLinks(helpLinkJson);
    setUnifiedEditor(schema);

    //add script for preview  
    const previewSrc = CE_BUNDLE_URL;
    const script = document.createElement('script');
    script.src = `${previewSrc}?v=${new Date().getTime()}`;
    script.defer = 'defer';
    document.body.appendChild(script);

    fetchPreviews(queryParams, isPlatformWix,
      response => {
        updateResponse(response?.data, schema);
        addDefaultWidget(response?.data, queryParams.preview, queryParams.isNewVariant, schema, storeInfoRes.editor_onboarding_status)
        setIsLoading(false);
      },
      err => {
        console.error(err);
        setIsLoading(false);
      });
    handleLoading(false);
  }

  const setMasterWidgetForWix = (response, schema) => {
    if (response.masterWidgets?.length > 0) {
      const masterWidgets = response.masterWidgets[0].widgets;
      const editorSchema = cloneDeep(schema);
      const defaultWidgets = editorSchema.defaultSections;

      masterWidgets.forEach((widget) => {
        const index = defaultWidgets.findIndex((defaultWidget) => defaultWidget.type === widget.type);
        if (index > -1) {
          defaultWidgets[index] = widget
        }
      });

      editorSchema.defaultSections = defaultWidgets;
      setUnifiedEditor(editorSchema);
    }
  }

  /**
   * To add default multi upsell widget for checkout
   * @param {*} previewType : type of preview
   * @param {*} response: Preview data 
   * @param {*} isNewVariant : add default widget or not
   * @param {*} schema : json schema to add default widget
   */
  const addDefaultWidget = (response, previewType, isNewVariant, schema, editorOnBoardingStatus) => {

    if (isNewVariant === 'true' && response) {
      if(isPlatformWix) {
        setMasterWidgetForWix(response, schema);
      }

      // Add default widget only if wix and onboarding status not done or for shopify platform
      if (!(isPlatformWix && editorOnBoardingStatus === false) && response.widgets?.length === 0) {
        const defaultWidgetType = previewType === PreviewType.POST_PURCHASE ? WidgetType.Upsell : WidgetType.MultiProductUpsell;
        const addSectionOptions = schema.addSectionOptions;
        const index = addSectionOptions.findIndex((item) => item.value.includes(defaultWidgetType))
        setIsInitWidgetEmpty(true);
        setAddWidgetByTarget(addSectionOptions[index].value);
      }
    }
  }

  /**
   *  Convert preview type for flow editor
   * @param {*} previewType - type of preview coming from flow builder
   * @returns preview type for flow editor
   */
  const convertPreviewType = (previewType) => {
    switch(previewType) {
      case 'POST_PURCHASE_PAGE1':
      case 'POST_PURCHASE_PAGE2':
        return PreviewType.POST_PURCHASE;
      default:
        return PreviewType[previewType.toUpperCase()];   
    }
  }

  /**
   * Parse response and set information of store
   * @param {*} queryParams URL query param
   * @param {*} response response of store info
   */
  const setStoreInfoResponse = (queryParams, response) => {
    setPreviewType(queryParams.preview);
    setSystemSetting(response.data);
    if (isPlatformWix && response.data.editor_onboarding_status === false) {
      setOnBoardingModalData({ ...onBoardingModalData, showModal: true })
    }
    setIsAuthorized(true);
    setDataForEditor(queryParams, response.data);
  }

  const getInitialData = async () => {
    const params = new URLSearchParams(window.location.search);
    const initialQueryParam = {
      token: params.get(QueryParams.Token),
      variantId: params.get(QueryParams.VariantId),
      preview: convertPreviewType(params.get(QueryParams.Type) || PreviewType.CHECKOUT),
      platformStoreId: params.get(QueryParams.PlatformStoreId),
      isNewVariant: params.get(QueryParams.IsNewVariant),
      redirectUrl: params.get(QueryParams.RedirectUrl),
      maxDiscount: params.get(QueryParams.MaxDiscount),
      autoActivation: params.get(QueryParams.AutoActivation),
      product: params.get(QueryParams.Product),
    }
    setQueryParams(initialQueryParam);

    if (initialQueryParam.platformStoreId) {
      sessionStorage.setItem('platformStoreId', initialQueryParam.platformStoreId);
      setPlatformStoreId(initialQueryParam.platformStoreId);
    }

    // Have to check token is exist because this is used for onboarding flow when user comes from payment page
    if (IS_LOCAL || initialQueryParam.token) {
      verifyAuth(initialQueryParam,
        async () => {
          // If merchant access direct editor then remove token from url and redirect to editor page
          if(!isOpenedFromFlowBuilder() && initialQueryParam.redirectUrl){
            const urlObj = new URL(document.location.href);
            urlObj.searchParams.delete(QueryParams.Token);
            document.location.href  = urlObj.toString();
          }
          await fetchStoreInfo()
            .then(res => {
              setStoreInfoResponse(initialQueryParam, res);
            })
            .catch(() => {
              setIsAuthorized(false);
              handleLoading(false);
            })
        },
        err => {
          setIsAuthorized(false);
          handleLoading(false);
          console.log(err);
        }
      );
    } else {
      await fetchStoreInfo()
        .then(res => {
          setStoreInfoResponse(initialQueryParam, res);
        })
        .catch(() => {
          setIsAuthorized(false);
          handleLoading(false);
        })
    }

  };

  const isRequiredDataFilled = useCallback(() => {
    if (requiredData.stopChanges) {
      if (!requiredData.showErrorMessage) {
        setRequiredData({ ...requiredData, showToast: true, showErrorMessage: true });
      } else {
        setRequiredData({ ...requiredData, showToast: true });
      }
      return false;
    } else {
      return true;
    }
  }, [requiredData]);

  const getUniqueByType = (obj) => {
    if (!obj || !Array.isArray(obj.blocks)) return obj; // Ensure obj and blocks array exist
    // Remove duplicates from blocks based on 'type'
    const seen = new Set();
    const uniqueBlocks = obj.blocks.filter(item => {
        if (!seen.has(item.type)) {
            item.count = 1;
            seen.add(item.type);
            return true;
        }
        return false;
    });

    // Return a new object with the updated blocks array
    return {
        ...obj, 
        blocks: uniqueBlocks
    };
  };

  const getWixDashboardUrl = useCallback(() => {
    return `https://manage.wix.com/dashboard/${systemSetting?.siteId}/app/${APP_ID}`
  }, [systemSetting?.siteId, APP_ID]);

  return (
    <>
      <PageData.Provider
        value={{
          getWixDashboardUrl,
          queryParams,
          isDynamicSync, 
          setIsDynamicSync,
          isPositionChangeOfWidget, 
          setIsPositionChangeOfWidget,
          sendPostMessage,
          setConfigSetting,
          setRedoConfigSetting,
          toastMessage, 
          setToastMessage,
          helpLinks,
          selectedWidgetType,
          setSelectedWidgetType,
          latestConfigSetting,
          checkRemovable,
          isInitWidgetEmpty,
          setIsInitWidgetEmpty,
          updateData,
          setIsCheckStatusDescription,
          isCheckStatusDescription,
          setIsCheckStatus,
          showCheckStatusButton,
          setIsAppInstalled,
          isAppInstalled,
          isAppIntegrated,
          setIsAppIntegrated,
          appInstallationUrl,
          setAppInstallationUrl,
          setIsMobileView,
          isMobileView,
          settingType,
          setSettingType,
          isLoading,
          configSetting,
          setUnifiedEditor,
          unifiedEditor,
          redoFun,
          undoFun,
          redoConfigSetting,
          showProgressBar,
          submitData,
          selectedSection,
          setSelectedSection,
          rightBarLinks,
          setRightBarLinks,
          systemSetting,
          selectedLink,
          setSelectedLink,
          previewSelectedSections,
          setPreviewSelectedSection,
          isRightBarEmpty,
          setIsRightBarEmpty,
          helpModalData,
          setHelpModalData,
          bannerStatus,
          setBannerStatus,
          previewType,
          isHideLeftBarBanner,
          setIsHideLeftBarBanner,
          isDiscountEnabled,
          isBannerEnable,
          addWidgetByTarget,
          setAddWidgetByTarget,
          requiredData,
          setRequiredData,
          isRequiredDataFilled,
          onBoardingModalData,
          setOnBoardingModalData,
          isSelectProduct,
          setIsSelectProduct,
          isAddSection,
          setIsAddSection,
          isPublishedData,
          setIsPublishedData,
          isOnboardSubmit,
          setIsOnboardSubmit,
          isShowWidgetLimitModal, 
          setIsShowWidgetLimitModal,
          rightBarData,
          setRightBarData,
          currentWidget,
          setCurrentWidget,
          upsellWidgetBlock,
          setUpsellWidgetBlock,
          isUpsellWidget,
          setIsUpsellWidget,
          selectedWidgetId,
          setSelectedWidgetId,
          getUniqueByType
        }}
      >
        {!isAuthorized ? <UnauthorizedPage /> : unifiedEditor === undefined ? null : children}
      </PageData.Provider>
    </>
  );
};
export default JsonData;
