import axios from 'axios';
import { SessionInterceptor } from '@reconvert/react-ui-component';
import { API_URL } from '../config';
import { QueryParams } from '../Constant';

const api = axios.create({
  baseURL: API_URL,
});

SessionInterceptor.addInterceptors(api);

api.interceptors.request.use(config => {
  const getSessionStorageValue = sessionStorage.getItem(QueryParams.PlatformStoreId) || '';
  const encryptedPlatformStoreId = btoa(getSessionStorageValue)

  if (encryptedPlatformStoreId) {
    if (!config.headers) {
      config.headers = {}
    }

    config.headers['X-Platform-Store-Id'] = encryptedPlatformStoreId;
    config.headers['X-Reconvert-Auth-Version'] = 1;
  }

  return config;
});

export const getInstanceID = () => {
  const params = window.location.search;
  const token = new URLSearchParams(params).get(QueryParams.Token);
  return token
}

// verify user 
export const verifyAuth = async (queryParams, res, err) => {

  api.get(`auth/login`, {
    headers: {
      Authorization: `Bearer ${queryParams.token}`
    }
  })
    .then(response => {
      if (response.status === 200 && queryParams.variantId) {
        res(response.data)
      } else {
        err(false);
      }
    })
    .catch(error => {
      console.error('API call error:', error);
      err(error);
    });
};

export const fetchStoreInfo = async () => {
  return await api.get(`store/info`)
}

export const fetchProductsWithSearch = async (q, endCursor, limit) => {
  let commonURL = `products?fields=isPhysical&limit=${limit}&onlyActive=1${endCursor ? `&endCursor=${endCursor}` : ''}${q ? `&q=${q}` : ''}`
  return api.get(`store/${commonURL}`)
};

export const fetchProduct = async (productId) => {
  return api.get(`store/products/${productId}`)
};

export const uploadProductImages = async (files) => {

  const formData = new FormData();

  files.forEach((file) => {
    formData.append(`files`, file);
  });

  return api.post(`store/assets/upload`, formData)
};

export const fetchProductImages = async () => {
  return api.get(`store/assets/images`)
};

export const fetchPreviews = async (queryParams, isWix, res, err,) => {
  api.get(`variants/${queryParams.variantId}${isWix && queryParams?.isNewVariant ? `?needsMasterInstanceData=${queryParams.isNewVariant}` : ''}`)
    .then(response => res(response))
    .catch(error => err(error));
};

export const updatePreview = (payload) => {
  return api.put(`variants/${payload.id}`, JSON.stringify(payload), {headers: {
    'Content-Type': 'application/json'
  }});
};

export const getCollectionsList = async (res, err) => {
  api.get('store/collections')
    .then(response => res(response))
    .catch(error => err(error));
}

export const getInstallationStatus = async (type) => {
  return api.get(`editor/integration-status?type=${type}`)
}

export const trackSegmentsService = async (payload) => {
  const response = await api.post(`segments/track`, payload);
  return response.data;
};

export const updateOnboardStatus = async (payload, callback, err) => {
  api.put(`store/info`, payload)
    .then(response => callback(response))
    .catch(error => err(error));
};

export const getToken = async (platformStoreId) => {
  return await api.post(`auth/token`, { platformStoreId: platformStoreId });
};