import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import '../../../../../Style/index.css';
import '../../../../../Style/customPolaris.css';
import '../../../../../Style/product.css';
import { PageData } from '../../../../../ContextAPI/Context';
import { getTargetedValue, getSelectedWidget, checkTargetConditions } from '../../../../../Utils/Common';
import { FilterStates } from '../FilterContext'
import { PlatformType } from '../../../../../Constant';
import { Header } from '../../../../../Shared/Components';
import { cloneDeep } from 'lodash';
import { getCollectionsList } from '../../../../../Service';
import { PLATFORM } from '../../../../../config';
import RenderComponent from '../Shared/RenderComponent';

const ProductUpsell = (props) => {
  const [title, setTitle] = useState('');
  const [widgetSettings, setWidgetSettings] = useState([]);
  const { configSetting, unifiedEditor, settingType, setSettingType, isMobileView ,setCurrentWidget} = useContext(PageData);
  const { filterIsOpen } = useContext(FilterStates);
  const [collections, setCollections] = useState([]);
  const { id, widgetId, parentWidgetId, target } = props.type;

  const latestSettings = useMemo(() => {
    return cloneDeep(configSetting[configSetting.length - 1]);
  }, [configSetting]);

  const fetchCollectionOptions = useCallback(() => {
    getCollectionsList(response => {
      if (response?.status === 200 && response?.data && response?.data?.collections && response?.data?.collections.length > 0) {
        const list = response?.data?.collections.map(e => ({ label: e.name, value: e.id }));
        setCollections(list);
      }
    },
      err => {
        console.error(err);
      });
  }, [])

  useEffect(() => {
    const targetedWidget = getTargetedValue(latestSettings, target, widgetId, parentWidgetId);
    const selectedWidget = getSelectedWidget(targetedWidget, unifiedEditor.widgets);
    setCurrentWidget(selectedWidget);
    setSettingType(getTargetedValue(latestSettings, `${target}.upsellInfo.type`, widgetId, parentWidgetId));
    setTitle(selectedWidget?.sectionName);
    setWidgetSettings(selectedWidget);
    if (PLATFORM === PlatformType.Wix) {
      fetchCollectionOptions();
    }
  }, [latestSettings, unifiedEditor, target, widgetId, parentWidgetId, setSettingType]);

  try {
    return (
      <div>
        {filterIsOpen.status === false ?
          <div className='section-details-wrap'>
            <Header label={title} showHelpLink/>
            {
              widgetSettings?.sectionSettings?.map((setting, index) => {
                return (
                  <React.Fragment key={index}>
                    {
                        checkTargetConditions({widgets: latestSettings, target: `${target}.${setting.target}`, conditions: setting.condition, widgetId: widgetId, parentWidgetId: parentWidgetId, _type: settingType, isMobileView}) || setting.conditional === false ?
                          <RenderComponent data={{ setting, id, widgetId, parentWidgetId, target, collections, componentType: settingType, syncSettings: widgetSettings?.syncSettings }} />
                          : null
                    }
                  </React.Fragment>
                )
              })
            }
          </div> : filterIsOpen.component}
      </div>
    )
  }
  catch (err) {
    return (
      <>
        <div className='m-5 text-center text-secondary'>Something went wrong..</div>
      </>
    )
  }
}

export default ProductUpsell;