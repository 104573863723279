import { useEffect, useMemo, useState } from 'react';
import { TextField } from '@shopify/polaris';
import { InputGroup, InputRightElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput as CharkraNumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';

import styles from './NumberInput.module.css';
import Label from '../Label';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';
import { t } from 'i18next';

const NumberInput = (props) => {
    const { tooltipText, showTooltip, label, onChange, min, max, suffix, placeholder, value: initialValue, minLimitWaringMessage, maxLimitWaringMessage, allowDecimal=false, helpText='' } = props;
    const [warningMessage, setWarningMessage] = useState('');
    const [value, setValue] = useState(initialValue || "");  // Initialize with prop value or empty
    const [prevValue, setPrevValue] = useState(initialValue || "");  // Also initialize previous value
    let timeoutId;

    // Effect to update the state if the value prop changes
    useEffect(() => {
        if (initialValue !== undefined) {
            setValue(initialValue);
            setPrevValue(initialValue);
        }
    }, [initialValue]);

    const clearWarningMessage = () => {
        setWarningMessage('');
    };

    const applyDecimal = useMemo(()=> { // if any block will have allowDecimal value as true then that block value can be used as decimal as well.
        return allowDecimal
    },[allowDecimal])

    useEffect(()=> {
        if(!applyDecimal && value)  setValue(parseInt(value))
    },[applyDecimal])

    const handleChange = (e) => {
        const inputValue = e;
        if (applyDecimal) {
            const decimalPoints = inputValue?.split(".")?.[1]?.length ?? 0;
            if (decimalPoints > 2) {
                return;
            }
        }
        let showErrorMessage = false;
        if (inputValue === "") {
            setPrevValue(value); // Store previous value before deletion
            setValue("");
        } else {
            setPrevValue(value); // Store previous valid value
            let numericValue;
            if(applyDecimal) {
                const isDecimalPoints = inputValue?.split(".")?.[1]?.length ?? 0;
                const pointValue = isDecimalPoints > 2 ? 2 : isDecimalPoints
                if(pointValue >= 1) {
                    numericValue = parseFloat(inputValue).toFixed(pointValue)
                } else {
                    numericValue = inputValue
                }
            } else {
                numericValue = parseInt(inputValue, 10);
            }
            
            //"value" having the previous value and "val" having current value when user incise the value if val is grater then max value user shows an error message.
            if ((numericValue >= max && numericValue === initialValue) || numericValue > max) {
                showErrorMessage = true;
                maxLimitWaringMessage && setWarningMessage(maxLimitWaringMessage);
            }
            // "value" having the previous value and "val" having current value when user decrease the value if val is less then min value user shows an error message.
            else if ((numericValue <= min && numericValue === initialValue) || numericValue < min) {
                showErrorMessage = true;
                minLimitWaringMessage && setWarningMessage(minLimitWaringMessage);
            } else {
                setWarningMessage('');
            }

            if (showErrorMessage) {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(clearWarningMessage, 5000);
            }

            if (numericValue >= min && numericValue <= max) {
                setValue(numericValue);
            } else if(numericValue > max || numericValue < min) {
                setPrevValue(initialValue);
            }
        }
    };

    // Handler blur event when user leaves input field.
    const handleBlur = (e) => {
        e.preventDefault()
        if (value === "") {
            setValue(prevValue); // Restore previous value if no new value was entered
        }
    };

    useEffect(() => {
        let handler;
        if (value !== "" && value !== initialValue) {
            handler = setTimeout(() => {
               onChange(value);
               clearTimeout(handler)
            }, 300);
        }
        return () => {
            handler && clearTimeout(handler); // Cleanup the timeout on unmount or value change
        };
    }, [value]);

    useEffect(() => {
        if (max && value && value > max) {
            onChange(max);
        }
    }, [max]);

    return (
        <div className={styles.input_wrap}>
            {label && <Label label={label} tooltipText={tooltipText} showTooltip={showTooltip} />}

            {PLATFORM === PlatformType.Wix ?
                <CharkraNumberInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                    min={min}
                    max={max}
                    clampValueOnBlur={false}
                >
                    <InputGroup>
                        <NumberInputField />
                        <InputRightElement className={styles.right_element}>
                            {suffix}
                        </InputRightElement>
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </InputGroup>
                </CharkraNumberInput>
                :
                <TextField
                    type={'number'}
                    suffix={suffix}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    min={min}
                    max={max}
                    error={warningMessage}
                    placeholder={placeholder}
                />
            }
            {helpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: t(helpText) }} />}
        </div>
    );
}

export default NumberInput;