import React, { useContext, useEffect, useRef } from 'react';
import { t } from 'i18next';
import { PageData } from '../../../../../ContextAPI/Context';
import VisibilityButtonGroup from '../VisibilityButtonGroup';
import { Icon } from '../../../../../Shared/Components';
import { PLATFORM } from '../../../../../config';
import { PlatformType, ThreeSectionLayout } from '../../../../../Constant';
import { componentModel } from '../../../Models/Component.model';
import '../../../../../Style/theme-block-section.css';
/*
Render blocks
*/
const Block = (props) => {
    const { block, index, widgets, onClick, isOpen, parentWidgetId, provided, isParentVisible, parentWidgetType, WidgetLayoutId } = props;
    const { selectedLink, setSelectedLink, setRightBarLinks, setSelectedSection, selectedSection, setPreviewSelectedSection, isRequiredDataFilled } = useContext(PageData);
    const blockRef = useRef();

    useEffect(() => {
        if (selectedLink?.id && selectedLink?.id === block.id && selectedLink?.parentSectionID === widgets?.id) {
            blockRef.current.click();
            setSelectedLink({});
        }
    }, [selectedLink, setSelectedLink, block.id, widgets]);

    useEffect(() => {
        // select draggable block
        if (selectedSection?.id === block.id && blockRef.current) {
            blockRef.current.click();
            setSelectedSection();
        }
    }, [block.id, blockRef]);

    const handleClick = (e) => {
        setRightBarLinks([]);
        if (isRequiredDataFilled()) {
            const isSameBlock = e.target.closest('.accordion-content').classList.contains('selected-item-active');
            Array.from(document.querySelectorAll('.selected-item-active')).forEach((el) => el.classList.remove('selected-item-active'));
    
            if (isSameBlock) {
                setPreviewSelectedSection({ id: null, parentId: null });
                onClick();
            } else {
                const modelData = {
                    widgetId: widgets.id,
                    parentWidgetId: parentWidgetId,
                    componentType: block.type,
                    count: block.count,
                    minCount: block.minCount,
                    blockType: block.type,
                    id: block.id,
                    target: block.target,
                    parentWidgetType: parentWidgetType
                }
                onClick(e, componentModel(modelData));
                e.target.closest('.accordion-content').classList.add('selected-item-active');
                if (ThreeSectionLayout.includes(widgets.type)) {
                    setPreviewSelectedSection({ id: parentWidgetId, parentId: parentWidgetId, isBlock: true });
                } else {
                    setPreviewSelectedSection({ id: widgets.id, parentId: parentWidgetId, isBlock: true });
                }
            }
            setRightBarLinks([]);
        }
    }

    const handleMouseEvent = (e) => {
        e.stopPropagation();
        setSelectedSection({ id: block?.id, isOpen: isOpen });
    }

    return (
        <React.Fragment key={index}>
            {block.maxCount >= block.count && block.count === 0 ? null :
                <ul className={` sub-menu ${!isOpen ? 'collapsed' : ''}`} key={index} >
                    <li ref={blockRef} key={index} id={`drag${index}`} className='accordion-content sub_list_data'
                        onClick={(e) => { handleClick(e) }} >
                        <span className='block-title-span'>
                            <span className='block-title'>
                                <Icon iconName={block.icon} iconAlt={block.blockName} className={PLATFORM === PlatformType.Shopify ? 'shopify_icon' : ''} />
                                {
                                    //TODO: For the Wix platform have block name is displayed as black color and Shopify light gray color
                                }
                                <div className={PLATFORM === PlatformType.Wix ? 'wix_color' : ''}>{t(block.blockName)}</div>
                            </span>
                        </span>
                        {<VisibilityButtonGroup handleMouseEvent={handleMouseEvent} provided={provided} item={block} widgetId={widgets.id} parentWidgetId={parentWidgetId} target={block.target} isParentVisible={isParentVisible} onClick={onClick} WidgetLayoutId={WidgetLayoutId}/>}
                    </li>
                </ul>}
        </React.Fragment>
    )
}

export default Block;